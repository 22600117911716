// ** React Imports
import React, { createContext, useState } from 'react'

// ** Onboarding Components
import InitialOnboarding from '../../components/onboarding/InitialOnboarding'

const OnboardingContext = createContext()

const OnboardingProvider = ({ children }) => {
    // ** States
    // Inicio
    const [initialOnboardingPendence, setStartInitialOnboardingPendence] = useState(false)
    const [initialOnboarding, setStartInitialOnboarding] = useState(false)

    const values = {
        // Inicio
        initialOnboardingPendence,
        setStartInitialOnboardingPendence: setStartInitialOnboardingPendence,
        initialOnboarding,
        setStartInitialOnboarding: setStartInitialOnboarding,
    }

    return (
        <OnboardingContext.Provider value={values}>
            {children}
            {/* Tour pelo Inicio */}
            {initialOnboarding ?
                <InitialOnboarding
                    isActive={initialOnboarding}
                    onFinish={() => {
                        setStartInitialOnboardingPendence(false)
                        setStartInitialOnboarding(false)
                    }}
                />
                : null}
        </OnboardingContext.Provider>
    )
}

export { OnboardingContext, OnboardingProvider }
