// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import { customToast } from '@utils'

export const getData = createAsyncThunk('appDemandas/getData', async (params = {}) => {
    params.projeto_id = localStorage.getItem('projetoSelecionado');
    const response = await axios.get('/demandas', { params })
    return { data: response.data, params }
})

export const getDemanda = createAsyncThunk('appDemandas/getDemanda', async id => {
    const response = await axios.get(`/demandas/${id}`)
    return response.data
})

export const datatablesSlice = createSlice({
    name: 'demandas',
    initialState: {
        data: [],
        total: 0,
        params: {},
        selectedDemanda: null,
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.total = action.payload.totalPages
                state.params = action.payload.params
            })
            .addCase(getDemanda.fulfilled, (state, action) => {
                state.selectedDemanda = action.payload
            })
    }
})

export default datatablesSlice.reducer
