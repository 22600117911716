import axios from "axios";
import jwtDefaultConfig from "./jwtDefaultConfig";

// ** Third Party Components
import toast from 'react-hot-toast'

import { X } from 'react-feather'

// ** Custom Components
import Avatar from '@components/avatar'

const ToastContent = ({ t, title, texto }) => {
  return (
    <div className='d-flex'>
      <div className='me-1'>
        <Avatar size='sm' color='danger' icon={<X size={12} />} />
      </div>
      <div className='d-flex flex-column'>
        <div className='d-flex justify-content-between'>
          <h6>{title}</h6>
          <X size={12} className='cursor-pointer' onClick={() => toast.dismiss(t.id)} />
        </div>
        <span>{texto}</span>
      </div>
    </div>
  )
}

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false;

  // ** For Refreshing Token
  subscribers = [];

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    axios.defaults.baseURL = process.env.REACT_APP_API_URL || 'http://localhost:3333';

    // ** Request Interceptor
    axios.interceptors.request.use(
      (config) => {
        // ** Get token from localStorage
        const accessToken = this.getToken();

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          if(!config.headers.Authorization) {
            // ** eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
          }
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // ** const { config, response: { status } } = error
        const { config, response } = error;
        const originalRequest = config;

        toast.dismiss();

        if (response && response.status === 401) {
          toast(t => (
            <ToastContent t={t} title={'Efetue o login novamente'} texto={'Você não possui permissão para esta ação!'} />
          ))
          // window.location.href = '/login';
        } else if (
          response &&
          (
            response.status === 500 ||
            response.status === 409 ||
            response.status === 400
          )
        ) {
          toast(t => (
            <ToastContent t={t} title={response.data.error} />
          ))
        } else if (error.code === "ERR_NETWORK") {
          toast(t => (
            <ToastContent
              t={t}
              title={'Erro de conexão'}
              texto={'Erro ao conectar com nossos servidores, recarregue a página ou contate o suporte'}
            />
          ))
        } else {
          toast(t => (
            <ToastContent
              t={t}
              title={'Ops! Erro desconhecido'}
              texto={'Erro desconhecido ao conectar com nossos servidores, recarregue a página ou contate o suporte'}
            />
          ))
        }
        return Promise.reject(error);
      }
    );
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    );
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName);
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args);
  }

  forgotPassword(...args) {
    return axios.post(this.jwtConfig.forgotPassowrdEndpoint, ...args);
  }

  forgotPasswordChange(hash, ...args) {
    return axios.put(this.jwtConfig.forgotPassowrdEndpoint + `/${hash}`, ...args);
  }

  updateMyProfile(...args) {
    return axios.put(this.jwtConfig.myProfileEndpoint, ...args);
  }

  passwordChange(...args) {
    return axios.put(this.jwtConfig.myProfileEndpoint, ...args);
  }

  selectProject(...args) {
    return axios.put(this.jwtConfig.loginEndpoint, ...args);
  }
}
