// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"
import auth from './authentication'
import usuarios from './usuario'
import { gruposAcessoAdmin, gruposAcessoCliente } from './grupoAcesso'
import clientes from './cliente'
import usuariosCliente from './usuarioCliente'
import projetosCliente from './projetoCliente'
// * demandas
import demandas from './demanda'
import demandasEmAnalise from './demandas/emAnalise'
import demandasBacklogEscopo from './demandas/backlogEscopo'
import demandasBacklogForaEscopo from './demandas/backlogForaEscopo'
import demandasEmAndamento from './demandas/emAndamento'
import demandasHomologacao from './demandas/homologacao'
import demandasConcluidas from './demandas/concluido'


// plural, please
const rootReducer = {
    navbar,
    layout,
    auth,
    usuarios,
    usuariosCliente,
    projetosCliente,
    gruposAcessoAdmin, gruposAcessoCliente,
    clientes,
    demandas,
    demandasEmAnalise,
    demandasBacklogEscopo,
    demandasBacklogForaEscopo,
    demandasEmAndamento,
    demandasHomologacao,
    demandasConcluidas
}

export default rootReducer
