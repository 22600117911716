// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import { customToast } from '@utils'

import toast from 'react-hot-toast'

export const getData = createAsyncThunk('appProjetosCliente/getData', async params => {
  const response = await axios.get('/projetos', { params })
  return {
    data: response.data.data,
    totalPages: response.data.total,
    params
  }
})

export const resetProjeto = createAsyncThunk('appProjetosCliente/getProjeto', async id => {
  const response = await axios.get(`/projetos/${id}`)
  return response.data
})

export const getProjeto = createAsyncThunk('appProjetosCliente/getProjeto', async id => {
  const response = await axios.get(`/projetos/${id}`)
  return response.data
})

export const addProjeto = createAsyncThunk('appProjetosCliente/addProjeto', async (projeto, { dispatch, getState }) => {
  const toastId = toast.loading('Processando...');

  await axios.post('/projetos', projeto)
  await dispatch(getData(getState().projetosCliente.params))

  customToast('Tudo certo!', 'Projeto cadastrado com sucesso!', 'success', toastId);

  return projeto
})

export const updateProjeto = createAsyncThunk('appProjetosCliente/updateProjeto', async (projeto, { dispatch, getState }) => {
  const toastId = toast.loading('Processando...');

  await axios.put(`/projetos/${projeto.id}`, projeto)
  await dispatch(getData(getState().projetosCliente.params))

  customToast('Tudo certo!', 'Projeto atualizado com sucesso!', 'success', toastId);

  return projeto
})

export const deleteProjeto = createAsyncThunk('appProjetosCliente/deleteProjeto', async (id, { dispatch, getState }) => {
  const toastId = toast.loading('Processando...');

  await axios.delete(`/projetos/${id}`)
  await dispatch(getData(getState().projetosCliente.params))

  customToast('Tudo certo!', 'Projeto excluído com sucesso!', 'success', toastId);

  return id
})

export const datatablesSlice = createSlice({
  name: 'projetosCliente',
  initialState: {
    data: [],
    total: 0,
    params: {},
    selectedProjeto: null
  },
  reducers: {
    unselectProjeto: state => {
      state.selectedProjeto = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.total = action.payload.totalPages
        state.params = action.payload.params
      })
      .addCase(getProjeto.fulfilled, (state, action) => {
        state.selectedProjeto = action.payload
      })
  }
})

export const { unselectProjeto } = datatablesSlice.actions

export default datatablesSlice.reducer
