// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import { customToast } from '@utils'

import toast from 'react-hot-toast'

export const getDataAdmin = createAsyncThunk('appGruposAcesso/getDataAdmin', async (params = {}) => {
  params.admin = true;
  const response = await axios.get('/grupo-acesso', { params })
  return { data: response.data.data, totalPages: response.data.total }
})

export const getGrupoAcessoAdmin = createAsyncThunk('appGruposAcesso/getGrupoAcessoAdmin', async id => {
  const response = await axios.get(`/grupo-acesso/${id}`)
  return response.data
})

export const getDataCliente = createAsyncThunk('appGruposAcesso/getDataCliente', async (params = {}) => {
  params.admin = false;
  const response = await axios.get('/grupo-acesso', { params })
  return { data: response.data.data, totalPages: response.data.total }
})

export const getGrupoAcessoCliente = createAsyncThunk('appGruposAcesso/getGrupoAcessoCliente', async id => {
  const response = await axios.get(`/grupo-acesso/${id}`)
  return response.data
})

export const addGrupoAcesso = createAsyncThunk('appGruposAcesso/addGrupoAcesso', async (grupoAcesso, { dispatch, getState }) => {
  const toastId = toast.loading('Processando...');
  await axios.post('/grupo-acesso', grupoAcesso)
  await dispatch(getDataCliente(getState().gruposAcessoCliente.params))
  await dispatch(getDataAdmin(getState().gruposAcessoAdmin.params))

  customToast('Tudo certo!', 'Grupo cadastrado com sucesso!', 'success', toastId);

  return grupoAcesso
})

export const updateGrupoAcesso = createAsyncThunk('appGruposAcesso/updateGrupoAcesso', async (grupoAcesso, { dispatch, getState }) => {
  const toastId = toast.loading('Processando...');
  await axios.put(`/grupo-acesso/${grupoAcesso.id}`, grupoAcesso)
  await dispatch(getDataCliente(getState().gruposAcessoCliente.params))
  await dispatch(getDataAdmin(getState().gruposAcessoAdmin.params))

  customToast('Tudo certo!', 'Grupo atualizado com sucesso!', 'success', toastId);

  return usuario
})

export const deleteGrupoAcesso = createAsyncThunk('appGruposAcesso/deleteGrupoAcesso', async (id, { dispatch, getState }) => {
  const toastId = toast.loading('Processando...');
  await axios.delete(`/grupo-acesso/${id}`)
  await dispatch(getDataCliente(getState().gruposAcessoCliente.params))
  await dispatch(getDataAdmin(getState().gruposAcessoAdmin.params))

  customToast('Tudo certo!', 'Grupo deletado com sucesso!', 'success', toastId);

  return id
})

export const gruposAcessoAdmin = createSlice({
  name: 'gruposAcessoAdmin',
  initialState: {
    data: [],
    total: 0,
    params: {},
    selectedGrupo: null,
    loadingData: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getDataAdmin.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.total = action.payload.totalPages
        state.params = action.payload.params
        state.loadingData = false
      })
      .addCase(getDataAdmin.pending, (state, action) => {
        state.loadingData = true
      })
      .addCase(getGrupoAcessoAdmin.fulfilled, (state, action) => {
        state.selectedGrupo = action.payload
      })
  }
}).reducer

export const gruposAcessoCliente = createSlice({
  name: 'gruposAcessoCliente',
  initialState: {
    data: [],
    total: 0,
    params: {},
    selectedGrupo: null,
    loadingData: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getDataCliente.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.total = action.payload.totalPages
        state.params = action.payload.params
        state.loadingData = false
      })
      .addCase(getDataCliente.pending, (state, action) => {
        state.loadingData = true
      })
      .addCase(getGrupoAcessoCliente.fulfilled, (state, action) => {
        state.selectedGrupo = action.payload
      })
  }
}).reducer
