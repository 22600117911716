// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import { customToast } from '@utils'

import toast from 'react-hot-toast'

export const getData = createAsyncThunk('appClientes/getData', async params => {
  const response = await axios.get('/clientes', { params })
  return { data: response.data.data, totalPages: response.data.total, params }
})

export const getCliente = createAsyncThunk('appClientes/getCliente', async id => {
  const response = await axios.get(`/clientes/${id}`)
  return response.data
})

export const addCliente = createAsyncThunk('appClientes/addCliente', async (cliente, { dispatch, getState }) => {
  const toastId = toast.loading('Processando cadastrado...');
  await axios.post('/clientes', cliente)
  await dispatch(getData(getState().clientes.params))

  customToast('Tudo certo!', 'Cliente cadastrado com sucesso!', 'success', toastId);

  return cliente
})

export const updateCliente = createAsyncThunk('appClientes/updateCliente', async (cliente, { dispatch, getState }) => {
  await axios.put(`/clientes/${cliente.id}`, cliente)
  await dispatch(getData(getState().clientes.params))
  await dispatch(getCliente(cliente.id))

  customToast('Tudo certo!', 'Cliente atualizado com sucesso!', 'success');

  return cliente
})

export const deleteCliente = createAsyncThunk('appClientes/deleteCliente', async (id, { dispatch, getState }) => {
  await axios.delete(`/clientes/${id}`)
  await dispatch(getData(getState().clientes.params))

  customToast('Tudo certo!', 'Cliente excluído com sucesso!', 'success');

  return id
})

export const datatablesSlice = createSlice({
  name: 'clientes',
  initialState: {
    data: [],
    total: 0,
    params: {},
    selectedCliente: null,
    loadingData: false,
    loadingSelectedCliente: false,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.pending, (state, action) => {
        state.loadingData = true
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.total = action.payload.totalPages
        state.params = action.payload.params
        state.loadingData = false
      })
      .addCase(getCliente.pending, (state, action) => {
        state.loadingSelectedCliente = true
      })
      .addCase(getCliente.fulfilled, (state, action) => {
        state.selectedCliente = action.payload
        state.loadingSelectedCliente = false
      })
  }
})

export default datatablesSlice.reducer
