import axios from "axios";

export async function uploadFiles(files, tipoarquivo_id) {
  const formData = new FormData();

  Array.from(files).forEach((file) => {
    formData.append("arquivos", file);
  });

  formData.append("tipoarquivo_id", tipoarquivo_id);

  return axios.post("/arquivo", formData, {
    headers: { "Content-type": "multipart/form-data" },
  });
}
