// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

import { customToast } from "@utils";
import toast from "react-hot-toast";
import { uploadFiles } from "../../utility/uploadFiles";

export const getData = createAsyncThunk(
  "appDemandasEmAnalise/getData",
  async (params = {}) => {
    params.projeto_id = localStorage.getItem("projetoSelecionado");
    const response = await axios.get("/demandas", { params });
    return { data: response.data, params };
  }
);

export const addDemanda = createAsyncThunk(
  "appDemandasEmAnalise/addDemanda",
  async (demanda, { dispatch, getState }) => {
    demanda.projeto_id = localStorage.getItem("projetoSelecionado");
    const toastId = toast.loading("Processando solicitação...");

    if (demanda.files.length) {
      const {
        data: { arquivos },
      } = await uploadFiles(demanda.files, "4");

      demanda.files = arquivos.map((arquivo) => ({ url: arquivo.path }));
    }

    await axios.post("/demandas", demanda);
    customToast(
      "Tudo certo!",
      "Solicitação feita com sucesso!",
      "success",
      toastId
    );
    await dispatch(
      getData({ ...getState().demandas.params, status_demanda: "em_analise" })
    );
    return demanda;
  }
);

export const datatablesSlice = createSlice({
  name: "demandasEmAnalise",
  initialState: {
    data: [],
    total: 0,
    params: {},
    loadingData: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getData.pending, (state, action) => {
        state.loadingData = true;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.total = action.payload.totalPages;
        state.params = action.payload.params;
        state.loadingData = false;
      });
  },
});

export default datatablesSlice.reducer;
