// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('appDemandasConcluidas/getData', async (params = {}) => {
    params.projeto_id = localStorage.getItem('projetoSelecionado');
    const response = await axios.get('/demandas', { params })
    return { data: response.data, params }
})

export const datatablesSlice = createSlice({
    name: 'demandasConcluidas',
    initialState: {
        data: [],
        total: 0,
        params: {},
        loadingData: false
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.pending, (state, action) => {
                state.loadingData = true
            })
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.total = action.payload.totalPages
                state.params = action.payload.params
                state.loadingData = false
            })
    }
})

export default datatablesSlice.reducer
