// ** React Imports
import { Fragment, useState } from 'react'

// ** Reactstrap Imports
import {
    Row,
    Col,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
} from 'reactstrap'

// ** Icons Imports
import { Award, MessageSquare, Clipboard, Heart, TrendingUp, Clock, ThumbsUp } from 'react-feather'

const data = [
    {
        icon: <TrendingUp />,
        title: 'Andamento dos projetos/demandas',
        subtitle: 'Acompanhe o andamento e prazos dos projetos/demandas.'
    },
    {
        icon: <ThumbsUp />,
        title: 'Homologação de Demandas',
        subtitle: 'Confira as demandas que precisam de homologação e aprove em poucos clicks.'
    },
    {
        icon: <Clipboard />,
        title: 'Solicite Novas Demandas!',
        subtitle: 'Envie novas demandas. Nosso time analisará e retornará o mais breve possível.'
    },
    {
        icon: <Clock />,
        title: 'Acompanhamento das Horas',
        subtitle: 'Gerencie seus recursos. O tempo é um dos nossos ativos mais importantes.'
    }
]

const ReferEarnModal = ({ isActive, onFinish }) => {
    // ** State
    const [show, setShow] = useState(isActive)

    return (
        <Modal isOpen={show} onClosed={() => { onFinish() }} toggle={() => setShow(!show)} className='modal-dialog-centered modal-refer-earn modal-xl'>
            <ModalHeader className='bg-transparent' toggle={() => setShow(!show)}></ModalHeader>
            <ModalBody className='pb-5 px-sm-0'>
                <div className='px-sm-4 mx-50'>
                    <h1 className='text-center mb-1'>Bem vindo ao Painel do Cliente!</h1>
                    <p className='text-center mb-5'>
                        {/* Este é o nosso portal feito com o <Heart size={14} />
                        <br />
                        para facilitar seus processos com a gente! */}
                        Aqui você encontrará todo backlog de demandas com status, previsões, acompanhamento das horas e muito mais.
                    </p>
                    <Row className='mb-4'>
                        {data.map((item, index) => {
                            return (
                                <Col xs={12} lg={3} key={index}>
                                    <div className='d-flex justify-content-center mb-1'>
                                        <div className='modal-refer-earn-step d-flex width-100 height-100 rounded-circle justify-content-center align-items-center bg-light-primary'>
                                            {item.icon}
                                        </div>
                                    </div>
                                    <div className='text-center'>
                                        <h6 className='fw-bolder mb-1'>{item.title}</h6>
                                        <p>{item.subtitle}</p>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                    <div className='d-flex justify-content-center'>
                        <Button onClick={() => setShow(!show)} color='primary'>Ir para o painel!</Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ReferEarnModal
