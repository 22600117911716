// ** Third Party Components
import toast from 'react-hot-toast'

import { Check, X } from 'react-feather'

// ** Custom Components
import Avatar from '@components/avatar'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "")

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value
  const timezone = "T00:00:00-03:00";
  return new Intl.DateTimeFormat("pt-BR", formatting).format(new Date(value+timezone))
}

// RETURNS DATE DD/MM/YYYY TIMEZONE GMT-03:00 (BR STANDARD)
export const formatDateNumeric = (
  value,
  formatting = { month: "numeric", day: "numeric", year: "numeric" }
) => {
  if (!value) return value
  const timezone = "T00:00:00-03:00";
  return new Intl.DateTimeFormat("pt-BR", formatting).format(new Date(value+timezone))
}

export const getCurrentMonthAndYear = () => {
  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;

  return (month < 10 ? '0' + month : month ) + "/" + year;
}

export const getCurrentMonthAndShortYear = (date) => {
  const year = new Date(date).getFullYear().toString().slice(-2);
  const month = new Date(date).getMonth() + 1;

  return (month < 10 ? '0' + month : month ) + "/" + year;
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: "short", day: "numeric" }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" }
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem("userData")
export const getUserData = () => JSON.parse(localStorage.getItem("userData"))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return "/admin"
  if (userRole === "cliente") return "/projetos"
  return "/login"
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#7367f0", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed" // for input hover border-color
  }
})

export const customToast = (titulo, corpo = '', type = 'success', toastToRemove = null) => {

  const ToastContent = ({ t, type = 'success', titulo, corpo }) => {
    let CustomIcon = null;
    if (type == 'success') {
      CustomIcon = <Check size={12} />
    } else {
      CustomIcon = <X size={12} />
    }

    return (
      <div className='d-flex'>
        <div className='me-1'>
          <Avatar size='sm' color={type} icon={CustomIcon} />
        </div>
        <div className='d-flex flex-column'>
          <div className='d-flex justify-content-between'>
            <h6>{titulo}</h6>
            <X size={12} className='cursor-pointer' onClick={() => toast.dismiss(t.id)} />
          </div>
          <span>{corpo}</span>
        </div>
      </div>
    )
  }
  
  toast.dismiss(toastToRemove);

  return toast(t => (
    <ToastContent t={t} type={type} titulo={titulo} corpo={corpo} />
  ))
}


/**  
 * ESTILOS CONDICIONAIS DAS LINHAS DA GRID
 * O TAMANHO VARIA DE ACORDO COM O TITULO DA DEMANDA (ALGUNS TITULOS SÃO LIVROS)
 * 
 * ESTIMA O TAMANHO DA CEL DE ACORDO COM O TAMANHO DO TITULO
*/
export const createConditionalRowTitleStyles = (status, titulo) => {
  const defaultStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
  const defaultStylesHomologado = {
    background: '#EAFDE7',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const defaultStylesNaoHomologado = {
    background: '#FAE0E0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const objStyles = () => {
    if(titulo.length > 500) {
      return {
        height: '320px',
      }
    } 
    else if(titulo.length > 450 && titulo.length < 500 ) {
      return {
        height: '250px',
      }
    }
    else if(titulo.length > 350 && titulo.length < 450 ) {
      return {
        height: '200px',
      }
    }
    else if(titulo.length > 320 && titulo.length < 350 ) {
      return {
        height: '170px',
      }
    }
    else if(titulo.length > 250 && titulo.length < 320) {
      return {
        height: '150px',
      }
    } 
    else if(titulo.length > 100 && titulo.length < 150) {
      return {
        height: '80px',
      }
    } 
    else if(titulo.length > 150 && titulo.length < 200) {
      return {
        height: '90px',
      }
    } 
    else if(titulo.length > 200 && titulo.length < 250) {
      return {
        height: '120px',
      }
    } 
    
    else if(titulo.length < 100 && titulo.length > 50) {
      return {
        height: '60px',
      }
    } 
    else {
      return {
        height: '40px'
      }
    }
  }

  const objHml = objStyles();
  switch(status) {
    case 'Homologado (QA)':
      return {
        ...objHml,
        ...defaultStylesHomologado,
      }
    case 'Não Homologado (QA)':
      console.log(titulo.length, 'nao homol')
      const objNaoHml = objStyles();
      return {
        ...objNaoHml,
        ...defaultStylesNaoHomologado,
      }
    case 'Em Homologação (QA)':
      const objDefault = objStyles();
      return {
        ...objDefault,
        ...defaultStyles,
      }
    // case 'Impediment':
    //   const objDefaultImpediment = objStyles();
    //   return {
    //     ...objHml,
    //     ...defaultStylesNaoHomologado,
    //   }
    default:
      const objDefault2 = objStyles();
      return {
        ...objDefault2,
        ...defaultStyles,
      }
  }
}

