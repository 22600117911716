// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import { customToast } from '@utils'

import toast from 'react-hot-toast'

export const getData = createAsyncThunk('appUsers/getData', async params => {
  const response = await axios.get('/usuarios', { params })
  return { data: response.data.data, totalPages: response.data.total, params }
})

export const getUsuario = createAsyncThunk('appUsers/getUsuario', async id => {
  const response = await axios.get(`/usuarios/${id}`)
  return response.data
})

export const addUser = createAsyncThunk('appUsers/addUser', async (usuario, { dispatch, getState }) => {
  const toastId = toast.loading('Processando...');

  usuario.admin = true
  await axios.post('/usuarios', usuario)
  await dispatch(getData(getState().usuarios.params))

  customToast('Tudo certo!', 'Usuário cadastrado com sucesso!', 'success', toastId);

  return usuario
})

export const updateUser = createAsyncThunk('appUsers/updateUser', async (usuario, { dispatch, getState }) => {
  const toastId = toast.loading('Processando...');

  await axios.put(`/usuarios/${usuario.id}`, usuario)
  await dispatch(getData(getState().usuarios.params))

  customToast('Tudo certo!', 'Usuário atualizado com sucesso!', 'success', toastId);

  return usuario
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  const toastId = toast.loading('Processando...');

  await axios.delete(`/usuarios/${id}`)
  await dispatch(getData(getState().usuarios.params))

  customToast('Tudo certo!', 'Usuário excluído com sucesso!', 'success', toastId);

  return id
})

export const datatablesSlice = createSlice({
  name: 'usuarios',
  initialState: {
    data: [],
    total: 0,
    params: {
      admin: true
    },
    selectedUsuario: null
  },
  reducers: {
    unselectUsuario: state => {
      state.selectedUsuario = null
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.total = action.payload.totalPages
        state.params = action.payload.params
      })
      .addCase(getUsuario.fulfilled, (state, action) => {
        state.selectedUsuario = action.payload
      })
  }
})

export const { unselectUsuario } = datatablesSlice.actions

export default datatablesSlice.reducer
